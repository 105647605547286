import {
  Box,
  Card,
  CardContent,
  Container,
  CardHeader,
  Stack,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { TextField } from 'component/base/TextField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { components } from 'openapi-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPeriod } from '@fortawesome/pro-regular-svg-icons';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { useChangePassword } from 'api/auth';
import { useSnackbar } from 'notistack';

function addToArrayRandom(arr: string[], item: string) {
  arr.splice(((arr.length + 1) * Math.random()) | 0, 0, item);
}
function genPassword() {
  const digits = '0123456789';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const specialChars = '~`!@#$%^&*()-_+={}[]|/:;"\'<>,.?';
  const passwordArr: string[] = [];
  for (let i = 0; i <= 6; i++) {
    const randomNumber = Math.floor(Math.random() * lowercase.length);
    addToArrayRandom(passwordArr, lowercase.substring(randomNumber, randomNumber + 1));
  }
  for (let i = 0; i <= 3; i++) {
    const randomNumber = Math.floor(Math.random() * uppercase.length);
    addToArrayRandom(passwordArr, uppercase.substring(randomNumber, randomNumber + 1));
  }
  for (let i = 0; i <= 1; i++) {
    const randomNumber = Math.floor(Math.random() * specialChars.length);
    addToArrayRandom(passwordArr, specialChars.substring(randomNumber, randomNumber + 1));
  }
  for (let i = 0; i <= 2; i++) {
    const randomNumber = Math.floor(Math.random() * digits.length);
    addToArrayRandom(passwordArr, digits.substring(randomNumber, randomNumber + 1));
  }

  return passwordArr.join('');
}

function PasswordRule({ text, isMet }: { readonly text: string; readonly isMet: boolean }) {
  return (
    <Typography
      component="div"
      sx={theme => ({
        marginTop: '5px',
        fontWeight: 'bold',
        alignItems: 'center',
        color: isMet ? theme.palette.success.main : theme.palette.grey[500],
        '& svg': {
          verticalAlign: isMet ? 'middle' : 'top',
          width: '12px',
        },
      })}
    >
      {<FontAwesomeIcon icon={isMet ? faCheck : faPeriod} />}
      {text}
    </Typography>
  );
}

export const digitsRegex = /[0-9]+/;
export const lowercaseRegex = /[a-z]+/;
export const uppercaseRegex = /[A-Z]+/;
export const specialCharRegex =
  /[\~\`\!\@\#\$\%\^\&\*\(\)\-\_\+\=\{\}\[\]\|\/\:\;\"\'\<\>\,\.\?\\]+/;

export function PasswordStrength({ password }: { readonly password: string }) {
  const { t } = useTranslation();
  return (
    <Stack spacing={0.75}>
      <PasswordRule text={t('password_strength.eight_characters')} isMet={password.length >= 8} />
      <PasswordRule
        text={t('password_strength.lowercase_character')}
        isMet={lowercaseRegex.test(password)}
      />
      <PasswordRule
        text={t('password_strength.uppercase_character')}
        isMet={uppercaseRegex.test(password)}
      />
      <PasswordRule
        text={t('password_strength.number_character')}
        isMet={digitsRegex.test(password)}
      />
      <PasswordRule
        text={t('password_strength.special_character')}
        isMet={specialCharRegex.test(password)}
      />
    </Stack>
  );
}

type ChangePasswordFormData = components['schemas']['UpdatePasswordRequest'];

export default function ChangePassword() {
  const { t } = useTranslation();
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);
  const { mutateAsync, isPending } = useChangePassword();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<ChangePasswordFormData>({
    defaultValues: {
      current_password: '',
      new_password: '',
      new_password_confirm: '',
    },
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;
  const onSubmit = async (data: ChangePasswordFormData) => {
    await mutateAsync(data);
    enqueueSnackbar(t('password_updated_successfully'), {
      key: 'passwordUpdateSuccessfully',
      variant: 'success',
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      style: {
        paddingLeft: '50px',
      },
    });
    reset();
  };

  const newPassword = methods.watch('new_password');
  const verifyPassword = methods.watch('new_password_confirm');

  const isValidPassword = (password: string) => {
    if (
      !digitsRegex.test(password) ||
      !lowercaseRegex.test(password) ||
      !uppercaseRegex.test(password) ||
      !specialCharRegex.test(password) ||
      password.length < 8
    ) {
      return t('password_does_not_meet_criteria');
    } else if (newPassword && verifyPassword && newPassword !== verifyPassword) {
      return t('password_does_not_match');
    }
  };

  return (
    <main id="mainContent">
      <Container maxWidth="sm">
        <Card>
          <FormProvider {...methods}>
            <CardHeader title={t('change_password')} />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={1.5}>
                  <TextField
                    defaultValue=""
                    name="current_password"
                    label={t('existing_password')}
                    disabled={isPending}
                    fullWidth
                    rules={{ required: true }}
                  />
                  <TextField
                    defaultValue=""
                    name="new_password"
                    disabled={isPending}
                    fullWidth
                    label={
                      <Stack direction="row" justifyContent="space-between">
                        <Box>{t('new_password')}</Box>
                        <Link
                          sx={{ textDecoration: 'none' }}
                          onClick={() => {
                            const password = genPassword();
                            methods.setValue('new_password', password);
                            methods.setValue('new_password_confirm', password);
                          }}
                        >
                          {t('generate_password')}
                        </Link>
                      </Stack>
                    }
                    type={showNewPassword ? 'text' : 'password'}
                    endAdornment={
                      <Link
                        sx={{ textDecoration: 'none' }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {t(showNewPassword ? 'hide' : 'show')}
                      </Link>
                    }
                    rules={{
                      required: true,
                      validate: password => {
                        return isValidPassword(password);
                      },
                    }}
                  />
                  <PasswordStrength password={newPassword ?? ''} />
                  <TextField
                    defaultValue=""
                    name="new_password_confirm"
                    disabled={isPending}
                    fullWidth
                    label={t('confirm_new_password')}
                    endAdornment={
                      <Link
                        sx={{ textDecoration: 'none' }}
                        onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                      >
                        {t(showVerifyPassword ? 'hide' : 'show')}
                      </Link>
                    }
                    type={showVerifyPassword ? 'text' : 'password'}
                    rules={{
                      required: true,
                      validate: password => {
                        return isValidPassword(password);
                      },
                    }}
                  />
                  <ProgressiveButton
                    text={t('update_password')}
                    onClick={async () => {
                      await handleSubmit(onSubmit)();
                    }}
                    isLoading={isPending}
                  />
                </Stack>
              </form>
            </CardContent>
          </FormProvider>
        </Card>
      </Container>
    </main>
  );
}
